<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-select
          placeholder="审核状态"
          v-model="query.status"
          clearable
          @change="getList(1)"
        >
          
          <el-option
            v-for="(item, index) in this.const.WITHDRAW_STATUS"
            :label="item.name"
            :value="item.value"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="10">
        <eb-date-range v-model="dateRange" @change="changeDate"></eb-date-range>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>

        <el-button
          type="primary"
          @click="doExportWithdrawList"
          v-auth="this.per.EXPORT_USER_WITHDRAW"
          >导出提现信息</el-button
        >
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>

      <el-table-column label="申请人" prop="realName"></el-table-column>

      <el-table-column label="提现金额（员）" prop="money" align="right">
        <template #default="scope">
          <span>{{ this.util.formatNum(scope.row.money / 100) }}</span>
        </template>
      </el-table-column>

      <el-table-column label="提现方式" prop="way">
        <template #default="scope">
          <eb-badge
            :list="this.const.WITHDRAW_PAYMENT_TYPE"
            :target="scope.row.way"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="申请时间" prop="createAt"></el-table-column>

      <el-table-column label="审核时间" prop="checkTime"></el-table-column>

      <el-table-column label="状态" prop="status">
        <template #default="scope">
          <eb-badge
            :list="this.const.WITHDRAW_STATUS"
            :target="scope.row.status"
          ></eb-badge>
        </template>
      </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span
            v-auth="this.per.USER_WITHDRAW_AUDIT"
            v-if="scope.row.status == this.const.WITHDRAW_STATUS_CODE.WAIT"
            class="option option-primary"
            @click="doAudit(scope.row, this.const.WITHDRAW_STATUS_CODE.PASS)"
            >通过审核</span
          >

          <span
            v-auth="this.per.USER_WITHDRAW_AUDIT"
            v-if="scope.row.status == this.const.WITHDRAW_STATUS_CODE.WAIT"
            class="option option-danger"
            @click="doAudit(scope.row, this.const.WITHDRAW_STATUS_CODE.REJECT)"
            >驳回审核</span
          >

          <span
            v-auth="this.per.USER_WITHDRAW_CONFIRM"
            v-if="scope.row.status == this.const.WITHDRAW_STATUS_CODE.PASS"
            class="option option-primary"
            @click="doUserOfflineWithdraw(scope.row)"
          >
            开始转账
          </span>

          <span
            class="option option-primary"
            @click="doWithdrawQuery(scope.row)"
          >
            转账结果查询
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { mapState } from "vuex";
import {
  userWithdrawAudit,
  fetchUserWithdrawList,
  userOfflineWithdraw,
  fetchWithdrawResult,
  exportWithdrawList,
} from "@/api/user";

import moment from "moment";

export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { map: {}, list: [] };
    },
  }),
  data() {
    let startTime = moment()
      .subtract(1, "m")
      .startOf("d")
      .format("YYYY-MM-DD HH:mm:ss");
    let endTime = moment().endOf("d").format("YYYY-MM-DD HH:mm:ss");

    return {
      dateRange: [startTime, endTime],
      queryDate: {
        startTime: startTime,
        endTime: endTime,
      },
      
      dataSource: [],
      query: {
        type: -1,
        way: -1,
        status: -1,
        bankId: -1,
        money: -1,
        operationId: -1,
      },
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    changeDate(e) {
      if (!this.validatenull(e)) {
        this.queryDate.startTime = moment(e[0]).format("YYYY-MM-DD HH:mm:ss");
        this.queryDate.endTime = moment(e[1])
          .endOf("d")
          .format("YYYY-MM-DD HH:mm:ss");
      }
    },

    doExportWithdrawList() {
      exportWithdrawList({
        startTime: this.queryDate.startTime,
        endTime: this.queryDate.endTime,
      }).then((res) => {
        this.util.apiDownLoadFile(
          res.data,
          "application/vnd.ms-excel",
          `${this.queryDate.startTime}-${this.queryDate.endTime}提现记录.xlsx`
        );
      });
    },
    // 修改查询条件
    changeArea(e) {
      if (!this.validatenull(e)) {
        this.query.provinceId = e[0];
        this.query.cityId = e[1];
        this.query.areaid = e[2];
      } else {
        this.query.provinceId = "";
        this.query.cityId = "";
        this.query.areaid = "";
      }
      this.getList(1);
    },

    // 转账查询
    doWithdrawQuery(row) {
      fetchWithdrawResult({ id: row.id }).then((res) => {
        this.getList();
      });
    },

    // 线下转账
    doUserOfflineWithdraw(data) {
      let s = <div>是否开始转账？</div>;
      this.$confirm(s, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((confirm) => {
        userOfflineWithdraw({
          id: data.id,
          status: 3,
          way: data.way,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getList();
        });
      });
    },

    //提现申请审核
    doAudit(data, type) {
      let pass = <span class="text-primary">通过</span>;
      let reject = <span class="text-danger">驳回</span>;
      let s = (
        <div>
          是否要 {type === this.const.WITHDRAW_STATUS_CODE.PASS ? pass : reject}{" "}
          提现审核?
        </div>
      );
      this.$confirm(s, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        userWithdrawAudit({
          status: type,
          id: data.id,
        }).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });

          this.getList();
        });
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchUserWithdrawList({
        query: this.query,
        ...this.pages,
        iSortType: 2,
        sortField: "id",
      }).then((res) => {
        this.dataSource = res.data.data.records;
        this.total = res.data.data.total;
        this.dataSource.map((item) => {
          item.operationName = this.agentList.map[item.operationId];
        });
      });
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
